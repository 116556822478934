import { Link } from 'react-router-dom'
import redesignImg from '../images/redesign.webp'

function Footer() {
  return (
    <footer className="footer-area">
    <div className="container">
      <div className="f-inner">
        <div className="f-left">
          <Link to="/">
            <img src={redesignImg} alt="redesign" />
          </Link>
          <p>
          Disclaimer: 
            Get ready to dive into the world of exciting games designed
            purely for your entertainment. RoyalMapleCanoffers a free online
            social casino platform, where all game functions and rewards are
            virtual and have no real monetary value. This means you can
            enjoy the thrill of casino games without the risk of losing real
            money. Please note that RoyalMapleCan intended for persons
            over the age of 19. By using our platform, you confirm that you
            meet this age requirement.
          </p>
        </div>
        <div className="f-r">
          <div className="f-r-title">
            <h3>Contact Info</h3>
          </div>
          <div className="f-r-btm">
            <p>
              <span>Location:</span>
            </p>
            <p>
            492 Maple Avenue Toronto, ON M5H 1N4 Canada
            </p>
          </div>
          <div className="f-r-btm">
            <p>
              <span>Phone:</span>
            </p>
            <Link to="/">+1 (416) 314-9587</Link>
          </div>
          <ul>
            <li>
              <Link to="/">Terms of Service</Link>
            </li>
            <li>
              <Link to="/">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="copy">
        <span>
          © 2021 RoyalMapleCan.com Made by{" "}
          <Link to="/">RoyalMapleCan.com</Link>
        </span>
      </div>
    </div>
  </footer>
  )
}

export default Footer
